import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="min-h-screen bg-black text-white flex flex-col justify-center items-center p-8 text-center">
        <h1 className="text-[#c1ff2e] text-6xl font-bold mb-4">
          We love this game.
        </h1>
        <h2 className="text-[#c1ff2e] text-4xl mb-12">ask us anything!</h2>

        <p className="max-w-3xl mb-16 text-lg">
          Dive into the heart of tennis with us! We're your go-to source for all
          things tennis in Hampshire, England, blending high-energy insights
          with a deep passion for the game. We're here to keep your spirit high
          and your game sharp. Join us as we smash through the tennis world with
          enthusiasm, expertise, and a fierce sense of community.
        </p>

        <div className="mb-8">
          <div className="flex items-center justify-center mb-2">
            <div style={{ width: "165px" }}>
              <a href="/">
                <img
                  src="/assets/logo_logoText.svg"
                  alt="Tennis Hampshire Podcast"
                  className="w-30 h-auto"
                />
              </a>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "16px" }}>
          <a
            href="https://www.facebook.com/people/The-Tennis-Hampshire-Podcast/61560728287138/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/facebook.svg"
              alt="Facebook"
              style={{ width: "24px", height: "24px" }}
            />
          </a>
          <a
            href="https://www.instagram.com/thampshirepod/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/insta.svg"
              alt="Instagram"
              style={{ width: "24px", height: "24px" }}
            />
          </a>
          <a
            href="https://x.com/thampshirepod"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/assets/socialMediaIcons/x.svg"
              alt="Twitter"
              style={{ width: "24px", height: "24px" }}
            />
          </a>
        </div>

        <div
          style={{
            display: "flex",
            gap: "16px",
            marginTop: "32px",
            fontSize: "14px",
            color: "#D1FF4F",
          }}
        >
          <p>© 2024 Tennis Hampshire Podcast. All rights reserved</p>
        </div>
      </footer>

      {/* <div
        className="bg-black text-center text-white"
        style={{ fontSize: "10px", color: "white", marginTop: "10px" }}
      >
        Design and Branding by More Equal Design |{" "}
        <a
          href="https://www.mequald.com"
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          www.mequald.com
        </a>
      </div> */}
    </>
  );
}
