import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";
import Header from "./Header";
import {
  fetchEpisodes,
  preloadImages,
  fetchLatestEpisode,
} from "../utils/apiUtils";

export default function PodcastEpisodes() {
  const [scrollOffset, setScrollOffset] = useState(0);
  const [episodes, setEpisodes] = useState([]);
  const [latestEpisode, setLatestEpisode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const episodeHeight = 424;

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      setScrollOffset((prevOffset) => prevOffset + e.deltaY);
    };

    const fetchAndSetEpisodes = async () => {
      setIsLoading(true);
      const episodesWithImages = await fetchEpisodes();
      await preloadImages(episodesWithImages);
      setEpisodes(episodesWithImages);
      setIsLoading(false);
    };

    const fetchAndSetLatestEpisode = async () => {
      const latest = await fetchLatestEpisode();
      setLatestEpisode(latest);
    };

    fetchAndSetEpisodes(); // Fetch episodes initially
    fetchAndSetLatestEpisode(); // Fetch the latest episode

    const container = containerRef.current;

    if (!isLoading && container) {
      container.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const renderEpisode = (episode, index, columnIndex, totalEpisodes) => {
    const direction = columnIndex === 1 ? 1 : -1;
    const columnHeight = totalEpisodes * episodeHeight;
    const offset = (scrollOffset * direction) % columnHeight;
    let adjustedIndex = (index * episodeHeight + offset) % columnHeight;
    if (adjustedIndex < 0) adjustedIndex += columnHeight;

    // Adjust the index to render the episode earlier
    const renderOffset = 50; // Adjust this value as needed
    adjustedIndex -= renderOffset;
    if (adjustedIndex < 0) adjustedIndex += columnHeight;

    const isGrayscale = index % 2 === 0;

    return (
      <RouterLink to={`/episode/${episode.id}`} key={`${episode.id}-${index}`}>
        <div
          style={{
            position: "absolute",
            height: `${episodeHeight}px`,
            top: adjustedIndex,
            padding: "20px", // Adjust this value for equal spacing
            margin: "10px", // Add margin for spacing between episodes
            cursor: "pointer",
          }}
        >
          <img
            src={episode.artwork_url}
            alt={episode.title}
            width={300}
            height={384}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: isGrayscale ? "grayscale(100%)" : "none",
            }}
          />
          <h2
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              right: 0,
              color: "white",
              padding: "12px 16px",
              fontSize: "16px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {episode.title}
          </h2>
        </div>
      </RouterLink>
    );
  };

  const renderColumn = (columnIndex) => {
    const episodesPerColumn = Math.ceil(episodes.length / 3);
    const startIndex = columnIndex * episodesPerColumn;
    const endIndex = startIndex + episodesPerColumn;
    const columnEpisodes = episodes.slice(startIndex, endIndex);
    const extendedEpisodes = [...columnEpisodes, ...columnEpisodes];

    // Introduce a random offset for misalignment
    const randomOffset = Math.random() * 20 - 10; // Random value between -10 and 10

    return (
      <div
        style={{
          width: "33.33%",
          padding: "16px",
          overflow: "hidden",
          position: "relative",
        }}
        key={columnIndex}
      >
        {extendedEpisodes.map((episode, index) =>
          renderEpisode(episode, index, columnIndex, columnEpisodes.length)
        )}
      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <img
            src="/assets/logo.svg"
            alt="Loading Logo"
            width={100}
            height={100}
          />
        </div>
      ) : (
        <div
          ref={containerRef}
          style={{
            height: "250vh",
            position: "relative",
            top: "-300px",
            overflow: "hidden",
            display: "flex",
            backgroundColor: "black",
            color: "white",
            paddingLeft: "200px",
            paddingRight: "200px",
            marginTop: "-400px",
          }}
        >
          <nav
            style={{
              position: "fixed",
              top: "13px",
              left: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              padding: "20px",
              borderRadius: "8px",
              zIndex: 1000,
            }}
          >
            <Header align="left" fontSize="20px" />
          </nav>
          <div
            style={{
              position: "fixed",
              top: "32px",
              right: "32px",
            }}
          >
            <a href="/">
              <img
                src="/assets/logo.svg"
                alt="Infographic"
                width="85px"
                height={100}
              />
            </a>
          </div>
          {[0, 1, 2].map((columnIndex) => renderColumn(columnIndex))}
        </div>
      )}
    </>
  );
}
