import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import { Play, Pause } from "lucide-react";
import { fetchLatestEpisode } from "../utils/apiUtils";

export default function HeaderPage() {
  const [latestEpisode, setLatestEpisode] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(true);
  const audioRef = useRef(null);

  const parentWidth = 472;
  const circles = [
    { delay: 0, x: parentWidth, isPodcast: true },
    { delay: 0.05, x: parentWidth * 0.85714, color: "#B5E11C" },
    { delay: 0.1, x: parentWidth * 0.71429, color: "#A6C632" },
    { delay: 0.15, x: parentWidth * 0.57143, color: "#94AA42" },
    { delay: 0.2, x: parentWidth * 0.42857, color: "#82914B" },
    { delay: 0.25, x: parentWidth * 0.28571, color: "#6F774E" },
    { delay: 0.3, x: parentWidth * 0.14285, color: "#5A5E4B" },
    { delay: 0.35, x: 0, color: "#464542" },
  ];

  useEffect(() => {
    const fetchAndSetLatestEpisode = async () => {
      setLoading(true);
      try {
        const latest = await fetchLatestEpisode();
        setLatestEpisode(latest);
      } catch (error) {
        console.error("Error fetching latest episode:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAndSetLatestEpisode();
  }, []);

  const togglePlay = () => {
    if (audioRef.current) {
      const audio = audioRef.current;

      if (!isPlaying) {
        const playPromise = audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch((error) => {
              console.error("Error playing audio:", error);
            });
        }
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
      setDuration(audioRef.current.duration);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
        <img
          src="/assets/logo.svg"
          alt="Tennis Hampshire Podcast"
          className="w-30 h-auto mb-4"
        />
      </div>
    );
  }

  const progress = duration > 0 ? (currentTime / duration) * 100 : 0;

  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <header className="p-6 flex justify-between items-center">
        <img
          src="/assets/logoText.svg"
          alt="Tennis Hampshire Podcast"
          className="w-30 h-auto"
        />
        <a href="/">
          <img
            src="/assets/logo.svg"
            alt="Tennis Hampshire Podcast"
            className="w-30 h-auto"
          />
        </a>
      </header>

      <main className="flex-grow flex items-center justify-between p-6">
        <div
          className="relative"
          style={{
            width: `${parentWidth}px`,
            height: `${parentWidth}px`,
            position: "relative",
            left: "-200px",
          }}
        >
          {circles.map((circle, index) => (
            <motion.div
              key={index}
              className={`absolute top-1/2 left-1/2 w-full h-full rounded-full`}
              initial={{
                opacity: 1,
                scale: 1,
                x: -parentWidth * 1.5,
                y: "-50%",
                backgroundColor: circle.color,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                x: circle.x - parentWidth / 2,
                y: "-50%",
              }}
              transition={{
                delay: circle.delay,
                duration: 0.5,
                ease: "easeInOut",
              }}
              onClick={circle.isPodcast ? togglePlay : undefined}
              style={{
                zIndex: circles.length - index,
                border: circle.isPodcast ? `5px solid transparent` : "none",
              }}
            >
              {circle.isPodcast && latestEpisode && (
                <React.Fragment>
                  <div className="relative w-full h-full rounded-full overflow-hidden">
                    <img
                      src={
                        latestEpisode.artwork_url ||
                        "/assets/episodePictures/default.jpg"
                      }
                      alt="Latest episode"
                      className="w-full h-full object-cover rounded-full"
                      style={{
                        filter: "grayscale(100%)",
                      }}
                    />
                    <div className="absolute inset-0 bg-black bg-opacity-40 rounded-full flex items-center justify-center">
                      <button
                        className="custom-play-pause-button"
                        onClick={togglePlay}
                      >
                        <img
                          src={
                            isPlaying ? "/assets/play.svg" : "/assets/pause.svg"
                          }
                          alt={isPlaying ? "Pause" : "Play"}
                          className="custom-icon"
                        />
                      </button>
                    </div>
                  </div>
                  <audio
                    ref={audioRef}
                    src={latestEpisode.audio_url || ""}
                    onTimeUpdate={handleTimeUpdate}
                    onEnded={() => setIsPlaying(false)}
                  />
                </React.Fragment>
              )}
            </motion.div>
          ))}
        </div>

        <Header />
      </main>

      <div
        className="flex flex-col items-start mt-4 self-end mr-6"
        style={{
          width: "208px",
          marginBottom: "20px",
        }}
      >
        <div className="w-full h-0.5 bg-gray-700 rounded-full overflow-hidden relative">
          <motion.div
            className="absolute top-0 left-0 h-full bg-[#CAFB1F]"
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
          <motion.div
            className="absolute top-1/2 w-3 h-3 bg-[#CAFB1F] rounded-full -translate-y-1/2"
            style={{ left: `${(currentTime / duration) * 100}%` }}
          />
        </div>
        <div className="mt-2 text-sm font-medium text-[#CAFB1F]">
          what I'm listening to
        </div>
        <div className="text-sm text-white">
          {latestEpisode ? (
            <a
              href={`/episode/${latestEpisode.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {latestEpisode.title}
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-between w-full text-xs text-gray-400 mt-1">
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
}
