import React, { useState, useEffect, useRef } from "react";

export default function InfographicPage() {
  const containerRef = useRef(null);
  const [containerSize, setContainerSize] = useState({
    width: "100%",
    height: "100vh",
  });

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const { width } = containerRef.current.getBoundingClientRect();
        const height = window.innerHeight;
        setContainerSize({ width: `${width}px`, height: `${height}px` });
      }
    };

    // Initial update
    updateContainerSize();

    // Update on window resize
    window.addEventListener("resize", updateContainerSize);

    // Cleanup
    return () => window.removeEventListener("resize", updateContainerSize);
  }, []);

  return (
    <div
      ref={containerRef}
      className="w-full h-screen overflow-hidden bg-black"
    >
      <iframe
        src="/Infographics/Stats/index.html"
        title="Tennis Club Performance Visualization"
        width={containerSize.width}
        height={containerSize.height}
        style={{ border: "none", overflow: "hidden" }}
      />
    </div>
  );
}
