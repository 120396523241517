import { motion } from "framer-motion";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom"; // Import Link from react-router-dom

export default function Header({ align = 'right', fontSize = '32px' }) { // Accept a 'fontSize' prop with a default value
  return (
    <motion.nav
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }} // Simplified to always show
      // transition={{ duration: 0.3 }}
      //   className="text-right"
    >
      <ul
        className="space-y-4 text-3xl font-bold"
        style={{
          lineHeight: 0.8,
          textAlign: align, // Use the 'align' prop for text alignment
          fontSize: fontSize, // Use the 'fontSize' prop for font size
        }}
      >
        <li>
          <RouterLink
            to="/"
            smooth={true}
            duration={500}
            className="hover:text-[#c5ff00] cursor-pointer"
          >
            Home
          </RouterLink>
        </li>

        <li>
          <RouterLink
            to="/episodes"
            smooth={true}
            duration={500}
            className="hover:text-[#c5ff00] cursor-pointer"
          >
            Episodes
          </RouterLink>
        </li>
        <li>
          <RouterLink
            to="/infographics"
            smooth={true}
            duration={500}
            className="hover:text-[#c5ff00] cursor-pointer"
          >
            Infographics
          </RouterLink>
        </li>
        <li>
          <RouterLink
            to="/contact"
            smooth={true}
            duration={500}
            className="hover:text-[#c5ff00] cursor-pointer"
          >
            Contact Us
          </RouterLink>
        </li>
        <li>
          <RouterLink
            to="/about"
            smooth={true}
            duration={500}
            className="hover:text-[#c5ff00] cursor-pointer"
          >
            About Us
          </RouterLink>
        </li>
      </ul>
    </motion.nav>
  );
}
