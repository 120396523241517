
import React from 'react'

const Ticker = ({ text = "", speed = 1, isOrange = false }) => {
  const duration = 30 / speed; // Adjust this value to change the base speed

  return (
    <div
      className={`h-[10vh] flex items-center overflow-hidden ${
        isOrange ? 'bg-[#D35400] text-white' : 'bg-black text-[#CCCCCC]'
      }`}
    >
      <div
        className="whitespace-nowrap text-4xl font-bold animate-ticker"
        style={{
          animationDuration: `${duration}s`,
        }}
      >
        <span className="inline-block">{(text + " ").repeat(10)}</span>
      </div>
    </div>
  )
}


const TickerWall = () => {
  const tickerData = [
    { text: "There's a bit of a sleeper team in here who have gone undefeated so far.", speed: 0.4, isOrange: false },
    { text: "There are more than 2000 tennis players who actively take part in the Apsley League alone.", speed: 0.6, isOrange: true },
    { text: "They didn't have the players, so maybe they only had enough for half a team, and now the B team coming up has managed to complete them.", speed: 0.6, isOrange: false },
    { text: "It's quite hard to find a way into tennis for people. You found your way in, I found my way in. But it’s not easy!", speed: 0.4, isOrange: true },
    { text: "Are these rules really understood? Are they open to a little bit of creative interpretation? And should they change at some point?", speed: 0.7, isOrange: false },
    { text: "It’s pretty unlikely that two captains are going to look up at the sky and reach the same conclusion about whether to stop that fixture or not if one of them is already winning", speed: 0.6, isOrange: true },
    { text: "Would you rather have points in the bag like Riverside A or would you rather be Thornden A, who've beaten that team and now have to go on and play all the other sides?", speed: 0.5, isOrange: false },
    { text: "I'm sure the organizers had fun with this, but they decided to put Twyford A against David Lloyd West End A and Twyford B versus David Lloyd West End B on the same day", speed: 0.6, isOrange: true },
    { text: "They’ve moved comfortably away from the dreaded wooden spoon race we've been talking about in the last couple episodes, safely in mid-table now.", speed: 0.7, isOrange: false },
    { text: "The National League is the LTA's national competition, and it's a very broad competition over the country with three distinct tiers.", speed: 0.6, isOrange: true },
  ]

  return (
    <div className="h-screen w-screen overflow-hidden">
      {tickerData.map((ticker, index) => (
        <Ticker key={index} {...ticker} />
      ))}
    </div>
  )
}

export default function Component() {
  return (
    <>
      <style jsx global>{`
        @keyframes ticker {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }
        .animate-ticker {
          display: inline-flex;
          animation: ticker linear infinite;
        }
      `}</style>
      <TickerWall />
    </>
  )
}