import React, { useState } from "react";
import Header from "./Header";

const InfographicDetailPage = () => {
  const [currentInfographic, setCurrentInfographic] = useState(0);
  const infographics = [
    "/Infographics/Ranking/index.html",
    "/Infographics/Stats/index.html",
  ];
  const InfographicText = [
    "Club names have been harmonised to ensure continuity of data through the seasons. Where a club has had an A team throughout the league's history, their first team is labelled as A, even in seasons when the club fielded only one team. In 2017, David Lloyd acquired Virgin Active's Hampshire Tennis club, so all Virgin Active Hampshire Tennis teams from 2014-2017 have been relabelled as David Lloyd West End for this analysis, consistent with the club's team names since 2018",
    "Dive into the action with the Southampton Lawn Tennis Association (SLTA) Apsley League's top performers from 2021 to 2024. Discover surprise wins, tight matches, and the highs and lows that kept fans on edge. It's a story of resilience, skill, and strategy, perfect for those who love analysing the game's stats. Can you spot the biggest wins or emerging stars? Explore the trends and embrace the excitement of tennis like never before!",
  ];
  const infographicHeadeing = [
    "A must have, for line graph",
    "A must have, for line graph",
  ];
  const InfographicTitle = [
    "Final Standings by Season",
    "Relative Performance by Team",
  ];

  const handlePrevious = () => {
    setCurrentInfographic((prev) =>
      prev === 0 ? infographics.length - 1 : prev - 1
    );
  };

  const handleNext = () => {
    setCurrentInfographic((prev) =>
      prev === infographics.length - 1 ? 0 : prev + 1
    );
  };

  const handleInfographicClick = (index) => {
    setCurrentInfographic(index);
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: "#0a0a0a",
        color: "white",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      {/* Left Sidebar */}
      <nav
        style={{
          width: "423px",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh", // Full height for the sidebar
          position: "sticky", // Stick the sidebar in place
          top: 0,
          borderRight: "0.5px solid #b4ff39", // Vertical line beside sidebar
        }}
      >
        <div>
          <Header align="left" fontSize="20px" />

          <div
            style={{
              fontSize: "13px",
              color: "white",
              marginBottom: "32px",
              marginTop: "32px",
              position: "relative",
              top: "100px"
            }}
          >
            <h1
              style={{ color: "#b4ff39", fontSize: "20px", fontWeight: "bold" }}
            >
              {InfographicTitle[currentInfographic]}
            </h1>
            <p>{InfographicText[currentInfographic]}</p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "0px",
            }}
          >
            {/* Add the horizontal line here */}
            <hr style={{ border: "none", borderTop: "1px solid #b4ff39", margin: "16px 0", width:"390px" }} />
            
            <h2
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "#b4ff39",
                marginBottom: "8px",
              }}
            >
              Archive
            </h2>
            <ul style={{ listStyle: "none", padding: 0, marginBottom: "8px" }}>
              {InfographicTitle.map((item, index) => (
                <li key={index} style={{ marginBottom: "4px" }}>
                  <a
                    href="#"
                    style={{
                      color: currentInfographic === index ? "#b4ff39" : "#888",
                      textDecoration: "none",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleInfographicClick(index)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
            <div style={{ display: "flex", gap: "8px" }}>
              <button
                style={{
                  color: "#b4ff39",
                  fontSize: "18px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handlePrevious}
              >
                ▲
              </button>
              <button
                style={{
                  color: "#b4ff39",
                  fontSize: "18px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                ▼
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main
        style={{
          flex: 1,
          padding: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* This div wraps the iframe and enables scrolling */}
        <div
          style={{
            flex: 1,
            overflowY: "auto", // Only the infographic area scrolls
            marginTop: "55px", // Moves the iframe lower
          }}
        >
          <iframe
            src={infographics[currentInfographic]}
            style={{
              width: "100%",
              height: "calc(100vh - 64px)",
              border: "none",
            }}
            title="Infographics recent"
          />
        </div>
      </main>

      <div
        style={{
          position: "absolute",
          top: "32px",
          right: "32px",
        }}
      >
        <a href="/">
          <img src="/assets/logo.svg" width="85px" alt="Infographic" />
        </a>
      </div>
    </div>
  );
};

export default InfographicDetailPage;