import axios from "axios";

const API_URL = "https://www.buzzsprout.com/api/";
const mockImages = [
  "/assets/episodePictures/1.jpg",
  "/assets/episodePictures/2.jpg",
  "/assets/episodePictures/3.jpg",
  "/assets/episodePictures/4.jpg",
  "/assets/episodePictures/5.jpg",
  "/assets/episodePictures/6.jpg",
  "/assets/episodePictures/7.jpg",
  "/assets/episodePictures/8.jpg",
  "/assets/episodePictures/9.jpg",
  "/assets/episodePictures/10.jpg",
  "/assets/episodePictures/11.jpg",
];

export const fetchEpisodes = async (forceFetch = false) => {
  const appId = process.env.REACT_APP_APP_ID;
  const token = process.env.REACT_APP_BUZZSPROUT_API_KEY;
  let cachedEpisodes = [];

  try {
    const storedETag = localStorage.getItem("etag");
    const storedLastModified = localStorage.getItem("lastModified");

    try {
      const cachedData = localStorage.getItem("episodes");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (Array.isArray(parsedData)) {
          cachedEpisodes = parsedData;
        } else {
          throw new Error("Cached episodes is not an array");
        }
      }
    } catch (e) {
      console.error("Error parsing cached episodes:", e);
      localStorage.removeItem("episodes");
      cachedEpisodes = [];
    }

    const headers = {
      Authorization: `Token token=${token}`,
      "Content-Type": "application/json",
    };

    if (!forceFetch) {
      if (storedETag) headers["If-None-Match"] = storedETag;
      if (storedLastModified) headers["If-Modified-Since"] = storedLastModified;
    }

    const response = await axios.get(`${API_URL}${appId}/episodes.json`, {
      headers,
      validateStatus: (status) => status === 200 || status === 304,
    });

    if (response.status === 200) {
      let fetchedEpisodes = response.data;

      if (Array.isArray(fetchedEpisodes)) {
        fetchedEpisodes = fetchedEpisodes.filter(
          (episode) => episode.description && episode.description.trim() !== ""
        );

        if (response.headers["etag"]) {
          localStorage.setItem("etag", response.headers["etag"]);
        }
        if (response.headers["last-modified"]) {
          localStorage.setItem("lastModified", response.headers["last-modified"]);
        }
        localStorage.setItem("episodes", JSON.stringify(fetchedEpisodes));

        const episodesWithCustomImages = fetchedEpisodes.map(
          (episode, index) => ({
            ...episode,
            imageUrl: mockImages[index % mockImages.length],
          })
        );

        return episodesWithCustomImages;
      } else {
        throw new Error("Fetched episodes data is not an array");
      }
    } else if (response.status === 304) {
      console.log("Episodes not modified, checking cached data.");
      if (cachedEpisodes.length > 0) {
        cachedEpisodes = cachedEpisodes.filter(
          (episode) => episode.description && episode.description.trim() !== ""
        );

        const episodesWithCustomImages = cachedEpisodes.map(
          (episode, index) => ({
            ...episode,
            imageUrl: mockImages[index % mockImages.length],
          })
        );

        return episodesWithCustomImages;
      } else {
        console.log("Cache is empty, force fetching data.");
        if (!forceFetch) {
          return await fetchEpisodes(true);
        } else {
          console.error("Force fetch failed: No episodes available in cache.");
          return [];
        }
      }
    }
  } catch (error) {
    console.error("Error fetching episodes:", error);
    return [];
  }
};

export const fetchLatestEpisode = async () => {
  const episodes = await fetchEpisodes();
  return episodes.length > 0 ? episodes[0] : null;
};

export const preloadImages = async (episodesWithImages) => {
  const imagePromises = episodesWithImages.map((episode) => {
    return new Promise((resolve, reject) => {
      const img = document.createElement("img");
      img.src = episode.imageUrl;
      img.onload = resolve;
      img.onerror = reject;
    });
  });

  await Promise.all(imagePromises);
};

export const fetchEpisodeDetails = async (episodeId, forceFetch = false) => {
  const API_URL = `https://www.buzzsprout.com/api/${episodeId}.json`;
  const token = process.env.REACT_APP_BUZZSPROUT_API_KEY;
  let cachedEpisode = null;

  try {
    const storedETag = localStorage.getItem(`etag_${episodeId}`);
    const storedLastModified = localStorage.getItem(`lastModified_${episodeId}`);

    try {
      const cachedData = localStorage.getItem(`episode_${episodeId}`);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (parsedData && parsedData.id) {
          cachedEpisode = parsedData;
        } else {
          throw new Error("Cached episode data is invalid.");
        }
      }
    } catch (e) {
      console.error("Error parsing cached episode:", e);
      localStorage.removeItem(`episode_${episodeId}`);
      cachedEpisode = null;
    }

    const headers = {
      Authorization: `Token token=${token}`,
      "Content-Type": "application/json",
    };

    if (!forceFetch) {
      if (storedETag) {
        headers["If-None-Match"] = storedETag;
      }
      if (storedLastModified) {
        headers["If-Modified-Since"] = storedLastModified;
      }
    }

    const response = await axios.get(API_URL, {
      headers,
      validateStatus: (status) => status === 200 || status === 304,
    });

    if (response.status === 200) {
      const fetchedEpisode = response.data;

      if (fetchedEpisode && fetchedEpisode.id) {
        if (response.headers["etag"]) {
          localStorage.setItem(`etag_${episodeId}`, response.headers["etag"]);
        }
        if (response.headers["last-modified"]) {
          localStorage.setItem(`lastModified_${episodeId}`, response.headers["last-modified"]);
        }
        localStorage.setItem(`episode_${episodeId}`, JSON.stringify(fetchedEpisode));

        return fetchedEpisode;
      } else {
        throw new Error("Fetched episode data is invalid.");
      }
    } else if (response.status === 304) {
      console.log("Episode not modified, using cached data.");

      if (cachedEpisode) {
        return cachedEpisode;
      } else {
        console.log("Cache is empty, force fetching data.");
        if (!forceFetch) {
          return await fetchEpisodeDetails(episodeId, true);
        } else {
          throw new Error("Failed to fetch episode details.");
        }
      }
    }
  } catch (err) {
    console.error("Error fetching episode details:", err);
    throw new Error("Error fetching episode details.");
  }
};
