import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer.jsx";
import HeaderPage from "./components/HeaderPage";
import SlideShow from "./components/SlideShow.jsx";
import TickerWall from "./components/TickerWall.jsx";
import InfographicPage from "./components/infographicPage.jsx";
import EpisodesPage from "./components/EpisodesPage.jsx";
import EpisodeDetail from "./components/EpisodeDetailPage.jsx";
import TennisBallLoader from "./components/TennisBallLoader.jsx";
import AboutPage from "./components/AboutPage.jsx";
import ContactUsPage from "./components/ContactUsPage.jsx";
import InfographicDetailPage from "./components/InfographicDetailPage.jsx";
import MobilePodcastPlayer from "./components/mobile/MobilePlayerHomepage.jsx";
import useIsMobile from "./hooks/UseIsMobile.jsx";
import MobileInfographicScreen from "./components/mobile/MobileInfographicScreen.jsx";

function App() {
  const isMobile = useIsMobile();
  const episodesRef = useRef(null);

  useEffect(() => {
    if (isMobile && window.location.pathname === "/infographics") {
      setTimeout(() => {
        episodesRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Adjust the timeout duration if necessary
    }
  }, [isMobile]);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                {isMobile ? (
                  <>
                    <MobilePodcastPlayer />
                    <MobileInfographicScreen />
                  </>
                ) : (
                  <>
                    <HeaderPage />
                    <div id="episodes" ref={episodesRef}>
                      <SlideShow />
                    </div>
                    <div id="infographics">
                      <InfographicPage />
                    </div>
                    <div id="">
                      <TickerWall />
                    </div>
                    <Footer />
                  </>
                )}
              </>
            }
          />
          <Route path="/episodes" element={<EpisodesPage />} />
          <Route path="/episode/:id" element={<EpisodeDetail />} />
          {/* <Route path="/loader" element={<TennisBallLoader />} /> */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/Infographics" element={<InfographicDetailPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
