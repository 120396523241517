import React, { useState, useEffect } from "react";
import Header from "./Header";
import HamburgerMenu from "./HamburgerMenu";

const styles = {
  container: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "black",
    color: "white",
  },
  leftColumn: {
    width: "423px",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    position: "sticky",
    top: 0,
    borderRight: "0.5px solid #b4ff39",
  },
  rightColumn: {
    width: "66.67%",
    padding: "32px",
  },
  title: {
    color: "#C5FF00",
    fontSize: "32px",
    marginBottom: "16px",
  },
  description: {
    fontSize: "14px",
    lineHeight: "1.5",
  },
  content: {
    marginBottom: "64px",
  },
  paragraph: {
    fontSize: "18px",
    lineHeight: "1.5",
    marginBottom: "32px",
  },
  highlight: {
    color: "#C5FF00",
  },
};

export default function AboutPage() {
  const [isMobile, setIsMobile] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mobileStyles = {
    container: {
      ...styles.container,
      flexDirection: 'column',
    },
    leftColumn: {
      ...styles.leftColumn,
      width: '100%',
      height: 'auto',
      position: 'static',
      borderRight: 'none',
      borderBottom: '0.5px solid #b4ff39',
    },
    rightColumn: {
      ...styles.rightColumn,
      width: '100%',
    },
  };

  const currentStyles = isMobile ? mobileStyles : styles;

  return (
    <div style={currentStyles.container}>
      <div style={currentStyles.leftColumn}>
        {isMobile ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <HamburgerMenu
              isOpen={isMenuOpen}
              toggleMenu={setIsMenuOpen}
              handleMenuItemClick={() => {}}
            />
          </div>
        ) : (
          <Header align="left" fontSize="20px" />
        )}
        <div>
          <h2 style={styles.title}>About Us</h2>
          <p style={styles.description}>
            Dive into the heart of tennis with us! We're your go-to source for
            all things tennis in Hampshire, England, blending high-energy
            insights with a deep passion for the game. we're here to keep your
            spirit high and your game sharp. Join us as we smash through the
            tennis world with enthusiasm, expertise, and a fierce sense of
            community.
          </p>
        </div>
      </div>

      <div style={currentStyles.rightColumn}>
        <div style={styles.content}>
          <p style={styles.paragraph}>
            Co-hosts Tom Mitchell and Dave Ernsberger are avid tennis players
            plying their trade throughout Hampshire's tennis leagues and
            ladders, playing at, representing and sometimes captaining teams
            from clubs as diverse as Littleton, David Lloyd West End, Twyford,
            Winchester and more. Between them, one of them is likely a member of
            a club near you.
          </p>
        </div>
        {/* Commented out image section
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            src="/placeholder.svg"
            alt="A black and white portrait of a dog"
            style={{ width: "48%", height: "auto" }}
          />
          <img
            src="/placeholder.svg"
            alt="A black and white portrait of another dog"
            style={{ width: "48%", height: "auto" }}
          />
        </div>
        */}
        <p style={styles.highlight}>
          They are both brilliant players, in their minds.
        </p>
      </div>
    </div>
  );
}
