import React, { useRef, useEffect, useState } from "react";
import { Menu } from "lucide-react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const HamburgerMenu = ({ isOpen, toggleMenu, handleMenuItemClick, menuSide = 'left', right }) => {
  const buttonRef = useRef(null); // Create a ref for the button
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 }); // State for menu position

  useEffect(() => {
    if (buttonRef.current && isOpen) {
      const { bottom, left, right: buttonRight } = buttonRef.current.getBoundingClientRect(); // Get button position
      setMenuPosition({ 
        top: bottom, 
        left: menuSide === 'right' ? (right || buttonRight - 100) : left // Adjust position based on menuSide and right prop
      }); 
    }
  }, [isOpen, menuSide, right]); // Update position when the menu opens, menuSide, or right changes

  return (
    <>
      <button ref={buttonRef} className="text-white" onClick={toggleMenu}>
        <Menu size={24} />
      </button>
      {isOpen && (
        <div
          className="absolute bg-gray-800 p-4 rounded shadow-lg"
          style={{ top: menuPosition.top, left: menuPosition.left-100 }} // Use dynamic position
        >
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </div>
      )}
    </>
  );
};

export default HamburgerMenu;
