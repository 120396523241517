"use client";

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { fetchEpisodes } from "../../utils/apiUtils";
import AudioPlayer from "../AudioPlayer";
import { useSwipeable } from "react-swipeable";
import { Menu, ChevronLeft, ChevronRight } from "lucide-react";
import HamburgerMenu from "../HamburgerMenu"; // Import the new component

export default function Component() {
  const [currentEpisode, setCurrentEpisode] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [visibleEpisodes, setVisibleEpisodes] = useState([]);
  const [episodeStartIndex, setEpisodeStartIndex] = useState(0);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isLoadingEpisodeDetails, setIsLoadingEpisodeDetails] = useState(false); // Add state for loading episode details
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for menu visibility

  useEffect(() => {
    const loadEpisodes = async () => {
      setLoading(true); // Set loading to true when fetching episodes
      try {
        const fetchedEpisodes = await fetchEpisodes();
        setEpisodes(fetchedEpisodes);
        setCurrentEpisode(fetchedEpisodes[0]);
        setVisibleEpisodes(fetchedEpisodes.slice(0, 4)); // Show first 4 episodes
      } catch (error) {
        console.error("Error fetching episodes:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    loadEpisodes();
  }, []);

  const skipToNextEpisode = async () => {
    const currentIndex = episodes.findIndex(
      (ep) => ep.id === currentEpisode.id
    );
    if (currentIndex !== -1 && currentIndex < episodes.length - 1) {
      const nextEpisode = episodes[currentIndex + 1];
      setIsLoadingEpisodeDetails(true); // Set loading state for episode details
      setCurrentEpisode(nextEpisode);
      // Simulate loading delay for episode details
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust delay as needed
      setIsLoadingEpisodeDetails(false); // Reset loading state
    }
  };

  const skipToPreviousEpisode = async () => {
    const currentIndex = episodes.findIndex(
      (ep) => ep.id === currentEpisode.id
    );
    if (currentIndex > 0) {
      const previousEpisode = episodes[currentIndex - 1];
      setIsLoadingEpisodeDetails(true); // Set loading state for episode details
      setCurrentEpisode(previousEpisode);
      // Simulate loading delay for episode details
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust delay as needed
      setIsLoadingEpisodeDetails(false); // Reset loading state
    }
  };

  const updateVisibleEpisodes = (direction) => {
    let newStartIndex = episodeStartIndex;
    if (direction === "next" && episodeStartIndex + 4 < episodes.length) {
      newStartIndex += 4;
    } else if (direction === "prev" && episodeStartIndex >= 4) {
      newStartIndex -= 4;
    }
    setEpisodeStartIndex(newStartIndex);
    setVisibleEpisodes(episodes.slice(newStartIndex, newStartIndex + 4));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: skipToPreviousEpisode,
    onSwipedRight: skipToNextEpisode,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const episodeListSwipeHandlers = useSwipeable({
    onSwipedLeft: () => updateVisibleEpisodes("next"),
    onSwipedRight: () => updateVisibleEpisodes("prev"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
  };

  const handleMenuItemClick = (path) => {
    navigate(path); // Navigate to the selected path
    setIsMenuOpen(false); // Close the menu after selection
  };

  if (loading) {
    // Check if loading
    return (
      <div className="min-h-screen bg-black text-white flex flex-col items-center justify-center">
        <img
          src="/assets/logo.svg"
          alt="Loading..."
          className="w-30 h-auto mb-4"
        />
      </div>
    ); // Return loading indicator
  }

  if (!currentEpisode) {
    return <div>Loading...</div>;
  }

  return (
    <div
      className="h-screen bg-black text-white font-sans flex flex-col"
      ref={containerRef}
    >
      {/* Header replaced with HamburgerMenu */}
      <div className="flex justify-between items-center p-4 z-10">
        <img src="/assets/logo_logoText.svg" alt="Logo" className="h-8" />
        <HamburgerMenu
          isOpen={isMenuOpen}
          toggleMenu={toggleMenu}
          handleMenuItemClick={handleMenuItemClick}
        />
      </div>

      {/* Main content */}
      <div className="flex-grow flex flex-col">
        <div
          {...swipeHandlers}
          className="w-full flex justify-center items-center"
          style={{ height: "50vh", backgroundColor: "#333" }}
        >
          {isLoadingEpisodeDetails ? ( // Show loading state for episode details
            <div className="min-h-screen text-white flex flex-col items-center justify-center">
              <img
                src="/assets/logo.svg"
                alt="Loading..."
                className="w-30 h-auto mb-4"
              />
            </div>
          ) : (
            <img
              src={currentEpisode.artwork_url}
              alt={currentEpisode.title}
              className="object-cover h-full w-full"
            />
          )}
        </div>
        <div className="flex-grow flex flex-col justify-between p-2">
          <div
            className="w-full flex-grow flex flex-col justify-center mb-2"
            style={{
              paddingLeft:"20px",
              paddingRight:"20px"
            }}
          >
            <AudioPlayer
              audioUrl={currentEpisode.audio_url}
              onEnded={() => setCurrentEpisode(null)}
              onNext={skipToNextEpisode}
              onPrev={skipToPreviousEpisode}
              fontSize="text-[10px]"
              disabled={isLoadingEpisodeDetails} // Disable audio player while loading
              // Add margin on both sides
            />
          </div>
          <div className="text-center mb-2">
            <h2 className="text-[16px] font-bold mb-1 text-center text-[#D1FF4F]">
              {currentEpisode.title}
            </h2>
            <div
              className={`h-12 ${
                isDescriptionExpanded ? "overflow-y-auto" : "overflow-hidden"
              }`}
            >
              <p className="text-xs">
                {currentEpisode.description.replace(/<[^>]*>/g, "")}
              </p>
            </div>
            <button
              className="mt-1 text-xs text-[#D1FF4F]"
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
            >
              {isDescriptionExpanded ? "Read Less" : "Read More"}
            </button>
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              className="text-[#D1FF4F]"
              onClick={() => updateVisibleEpisodes("prev")}
              disabled={isLoadingEpisodeDetails} // Disable button while loading
            >
              <ChevronLeft size={24} />
            </button>
            <div className="flex justify-between items-center space-x-7">
              {visibleEpisodes.map((episode, index) => (
                <div
                  key={episode.id}
                  className={`relative flex-shrink-0 cursor-pointer text-center ${
                    currentEpisode.id === episode.id ? "font-bold" : ""
                  }`} // Highlight current episode
                  onClick={() => setCurrentEpisode(episode)}
                  style={{
                    backgroundImage: `url(${episode.artwork_url})`,
                    backgroundSize: "cover",
                    width: "60px",
                    height: "60px",
                  }} // Set background image and size
                >
                  <div className="absolute inset-0 bg-gray-800 opacity-60" />{" "}
                  {/* Grey overlay */}
                  <div className="absolute top-0 left-0 text-[#D1FF4F] p-1">
                    {" "}
                    {/* Position text at top left */}
                    <div className="text-[10px]">THPod</div>
                    <div className="text-1xl">
                      {String(
                        episodes.length - (episodeStartIndex + index)
                      ).padStart(2, "0")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="text-[#D1FF4F]"
              onClick={() => updateVisibleEpisodes("next")}
              disabled={isLoadingEpisodeDetails} // Disable button while loading
            >
              <ChevronRight size={24} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
