import React, { useState, useEffect } from "react";
import Header from "./Header";
import HamburgerMenu from "./HamburgerMenu";
import useIsMobile from "../hooks/UseIsMobile";

const styles = {
  container: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "black",
    color: "white",
  },
  leftColumn: {
    width: "423px",
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    position: "sticky",
    top: 0,
    borderRight: "0.5px solid #b4ff39",
  },
  rightColumn: {
    width: "calc(100% - 423px)",
    padding: "32px",
  },
  title: {
    color: "#C5FF00",
    fontSize: "32px",
    marginBottom: "16px",
  },
  description: {
    fontSize: "14px",
    lineHeight: "1.5",
  },
  logo: {
    position: "absolute",
    top: "32px",
    right: "32px",
  },
  socialIcons: {
    display: "flex",
    gap: "16px",
    marginBottom: "32px",
  },
  contactInfo: {
    marginBottom: "16px",
  },
  form: {
    marginTop: "32px",
  },
  input: {
    width: "100%",
    padding: "8px",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #C5FF00",
    color: "white",
    marginBottom: "16px",
  },
  textarea: {
    width: "100%",
    padding: "8px",
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #C5FF00",
    color: "white",
    minHeight: "100px",
    marginBottom: "16px",
  },
  button: {
    backgroundColor: "#C5FF00",
    color: "black",
    padding: "8px 16px",
    border: "none",
    cursor: "pointer",
  },
};

export default function ContactUsPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useIsMobile(); // Use the useIsMobile hook
  console.log(isMobile)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
  };

  const handleMenuItemClick = (path) => {
    // Handle menu item click
    setIsMenuOpen(false); // Close the menu after selection
  };

  const mobileStyles = {
    container: {
      ...styles.container,
      flexDirection: "column",
    },
    leftColumn: {
      ...styles.leftColumn,
      width: "100%",
      height: "auto",
      position: "static",
      borderRight: "none",
      borderBottom: "0.5px solid #b4ff39",
    },
    rightColumn: {
      ...styles.rightColumn,
      width: "100%",
    },
  };

  const currentStyles = isMobile ? mobileStyles : styles;

  return (
    <div style={currentStyles.container}>
      <div style={currentStyles.leftColumn}>
        {isMobile ? ( // Render HamburgerMenu for mobile
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}> {/* Added this div to align HamburgerMenu to the right */}
            <HamburgerMenu
              isOpen={isMenuOpen}
              toggleMenu={toggleMenu}
              handleMenuItemClick={handleMenuItemClick}
            />
          </div>
        ) : (
          // Render regular header for larger screens
          <Header align="left" fontSize="20px" />
        )}
        <div>
          <h2 style={styles.title}>Contact Us</h2>
          <p style={styles.description}>
            We want to hear from you! Got questions, feedback, or ideas? Hit us
            up below or shoot us an email. Let's serve up some great
            conversations!
          </p>
        </div>
      </div>

      <div style={currentStyles.rightColumn}>
        {!isMobile && ( // Render logo only if in mobile view
          <div style={styles.logo}>
            <a href="/">
              <img src="/assets/logo.svg" width="85px" alt="Infographic" />
            </a>
          </div>
        )}
        <div>
          <div style={styles.socialIcons}>
            <a href="https://www.facebook.com/people/The-Tennis-Hampshire-Podcast/61560728287138/">
              <img
                src="/assets/socialMediaIcons/facebook.svg"
                alt="Facebook"
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a href="https://www.instagram.com/thampshirepod/">
              <img
                src="/assets/socialMediaIcons/insta.svg"
                alt="Instagram"
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a href="https://x.com/thampshirepod">
              <img
                src="/assets/socialMediaIcons/x.svg"
                alt="Twitter"
                style={{ width: "24px", height: "24px" }}
              />
            </a>
          </div>
          <div style={styles.contactInfo}>
            <p style={{ margin: "0", fontWeight: "bold" }}>Email:</p>
            <p style={{ margin: "0" }}>hello@thpod.uk</p>
          </div>
          <div style={styles.contactInfo}>
            <p style={{ margin: "0", fontWeight: "bold" }}>Phone:</p>
            <p style={{ margin: "0" }}>+44 7470 395289</p>
          </div>
          <div style={styles.contactInfo}>
            <p style={{ margin: "0", fontWeight: "bold" }}>Address:</p>
            <p style={{ margin: "0" }}>Winchester, United Kingdom</p>
          </div>
        </div>
        <form  style={styles.form}>
          <h2 style={{ ...styles.title, fontSize: "24px" }}>Get in touch</h2>
          <input
            type="text"
            name="name"
            value={formData.name}
            // onChange={handleInputChange}
            placeholder="Name"
            style={styles.input}
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            // onChange={handleInputChange}
            placeholder="Email"
            style={styles.input}
          />
          <textarea
            name="message"
            value={formData.message}
            // onChange={handleInputChange}
            placeholder="Message"
            style={styles.textarea}
          />
          <button type="submit" style={styles.button}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
}
