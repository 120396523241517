import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const pages = [
  { text: "Game.", color: "#000000", fontColor: "#C5FF00" }, // black
  { text: "Set.", color: "#FFFFFF", fontColor: "#D64800" }, // white
  { text: "Podcast.", color: "#003FA3", fontColor: "#FFFFFF" }, // 003FA3
  { text: "Game.", color: "#C5FF00", fontColor: "#003FA3" }, // C5FF00
  { text: "Set.", color: "#004B2E", fontColor: "#D64800" }, // 004B2E
  { text: "Podcast.", color: "#FFFFFF", fontColor: "#004B2E" }, // white
  { text: "Game.", color: "#4FE391", fontColor: "#000000" }, // 4FE391
  { text: "Set.", color: "#FFB879", fontColor: "#D64800" }, // FFB879
  { text: "Podcast.", color: "#000000", fontColor: "#FF6166" }, // black
  { text: "Game.", color: "#D64800", fontColor: "#FFB879" }, // D64800
  { text: "Set.", color: "#00B1F9", fontColor: "#C5FF00" }, // 00B1F9
  { text: "Podcast.", color: "#FF6166", fontColor: "#FFFFFF" }, // FF6166
];

export default function SlideShow() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % pages.length);
    }, 750); 

    return () => clearInterval(timer);
  }, []);

  const variants = {
    enter: {
      scale: 1.2,
      opacity: 0,
    },
    center: {
      scale: 1,
      opacity: 1,
    },
    exit: {
      scale: 1.2,
      opacity: 0,
    },
  };

  return (
    <div className="relative h-screen w-screen overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.div
          key={currentIndex}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: "tween", duration: 0.05 }}
          className="absolute inset-0 flex items-center justify-center"
          style={{ backgroundColor: pages[currentIndex].color }}
        >
          <h1
            className="text-6xl md:text-8xl lg:text-9xl font-bold text-center"
            style={{ color: pages[currentIndex].fontColor }}
          >
            {pages[currentIndex].text}
          </h1>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
